<template>
  <section class="content-container">
    <router-view/>
  </section>
</template>

<!-- <template>
  <div class="culture">
    <div class="culture-sidebar">
      <SideBar />
    </div>
    <div class="culture__container">
       <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <img src="@/assets/img/forma_2.svg" alt="" class="shape-tr" />
      <div class="admin__body">
        <div class="culture__body-top">
          <SectionTitle :title="title" subtitle="" />
          <ProfileNav />
        </div>
        <router-view />
        <footerSec></footerSec>
      </div>
    </div>
  </div>
</template> -->

<script>
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
/*    components: { SideBar, SectionTitle, ProfileNav, footerSec }, */
   data() {
      return {
         title: "Galería",
      };
   },
   mounted() {
      this.checkMyLogin(this.$route);
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
   },
};
</script>